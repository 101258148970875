import tw from "twin.macro";

import { css } from '@emotion/core'
import styled from '@emotion/styled/macro';

export const Anchor = tw.a`text-blue-ocean inline-block`;

export const H1 = tw.h1`text-2xl leading-10 font-bold mt-6`;

export const H2 = tw.h2`text-xl leading-9 font-bold mt-5`;

export const H3 = tw.h3`text-lg leading-8 font-bold mt-4`;

export const H4 = tw.h4`text-lg leading-4 font-semibold mt-4`;

export const H5 = tw.h5`leading-4 font-semibold mt-4`;

export const H6 = tw.h6`leading-4 font-semibold mt-4`;

export const Ol = styled.ol`
  ${tw`pl-2 mb-3 list-decimal list-inside`}
  & ul {${tw`pl-8`}}
  & ol {${tw`pl-8`}}
  `;

export const Ul = styled.ul`
  ${tw`pl-2 mb-3 list-disc list-inside`}
  & ul {${tw`pl-8`}}
  & ol {${tw`pl-8`}}
  `;

export const Table = tw.table`table-fixed my-4`;

export const Thead = tw.thead`border-b-2 border-blue-ocean`;

export const Img = tw.img`max-w-full h-auto`;

/* TODO: support align attr */
export const Td = tw.td`p-1`;

export const Tr = tw.tr`p-2 even:bg-indigo-100`;

export const Blockquote = tw.blockquote`block font-semibold my-2 pt-2 pl-4 border-solid border-l-4 border-blue-ocean`;

export const Quote = ({content, source}) => {
  const before_content = '——';

  return (
    <blockquote tw="block font-semibold my-2 pt-2 pl-4 border-solid border-l-4 border-blue-ocean">
      <p>{content}</p>
      <small tw="block pt-2 text-gray-500 italic">——{source || "unknown source"}</small>
    </blockquote>
)};

export const P = tw.p`leading-6 pb-2`;
