import React from "react";
import tw from "twin.macro";
import { graphql, Link } from "gatsby";
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from "gatsby-plugin-mdx";
import { BlogPostJsonLd, GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from "./layout";
import Tag from "./tag";
import * as element from "./element";

const kebabCase = require("lodash/kebabCase");


const PaginatorLink = ({data, left, right}) => {
  if (data) {
    return (
      <div tw="mx-1 mt-10 px-3 py-2 bg-gray-100 text-gray-500">
        <Link tw="flex text-blue-ocean items-center font-semibold" to={data.fields.slug}>
          <span tw="mx-1">{left} {data.frontmatter.title||'Untitled'} {right}</span>
        </Link>
      </div>
    );

  } else {
    return (
      <div tw="mx-1 mt-10 px-3 py-2 bg-gray-100 text-gray-500">
        <div tw="flex items-center font-semibold">
          <span tw="mx-1">No more</span>
        </div>
      </div>
    );
  }
};


const components = {
  a: element.Anchor,
  p: element.P,
  img: element.Img,
  h1: element.H1,
  h2: element.H2,
  h3: element.H3,
  h4: element.H4,
  h5: element.H5,
  h6: element.H6,
  blockquote: element.Blockquote,
  Quote: element.Quote,
  ol: element.Ol,
  ul: element.Ul,
  table: element.Table,
  thead: element.Thead,
  td: element.Td,
  tr: element.Tr,
};


export default function PageTemplate({ data: { mdx }, pageContext }) {
  const date = new Date(mdx.frontmatter.date).toLocaleDateString();

  return (
    <Layout>
      <GatsbySeo title={mdx.frontmatter.title||'Untitled'}
                 description={mdx.excerpt} />
      <BlogPostJsonLd
        title={mdx.frontmatter.title||'Untitled'}
        datePublished={mdx.frontmatter.date}
        authorName={mdx.frontmatter.author||'richard'}
        description={mdx.excerpt}
        publisherName='CCCC IM'
        publisherLogo='https://cccc.im/favicon-180.png' />
      <element.H1>
        {mdx.frontmatter.tags &&
         mdx.frontmatter.tags.includes("draft") &&
         <em tw="font-bold text-blue-ocean">[DRAFT]: </em>}
        {mdx.frontmatter.title||'untitled'}
      </element.H1>
      <span>{mdx.frontmatter.author}</span>

      {mdx.frontmatter.tags &&
       mdx.frontmatter.tags.map(tag => (
         <Tag key={kebabCase(tag)} to={`/tags/${kebabCase(tag)}/`}>
           {tag}
         </Tag>))}

      <div>at <time>{date}</time></div>
      <hr tw="border my-2"/>
      <MDXProvider components={components}>
        <MDXRenderer>
          {mdx.body}
        </MDXRenderer>
      </MDXProvider>
      <div tw="flex">
        <PaginatorLink data={pageContext.next} left="«" />
        <PaginatorLink data={pageContext.previous} right="»" />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: {eq: $id}) {
      id
      body
      frontmatter {
        title
        date
        tags
      }
    }
  }`;
